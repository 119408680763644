<template>
    <div class="o-container">
        <app-intro>
            <h1>Kies een stijl</h1>
            <h3>Welke stijl past het best bij uw branche?</h3>
        </app-intro>

        <loading v-bind:nodes="nodes">Bezig met laden van de stijlen...</loading>

        <transition-group class="c-styles" name="fade" tag="section">
            <card-style :class="'c-style-' + count" v-bind:card="node" :type="type" :index="index" v-for="(node, count) in nodes" :key="node.id"/>
        </transition-group>
    </div>
</template>

<script>
    import { mapState } from 'vuex';
    import AppIntro from '../components/app/AppIntro.vue';
    import CardStyle from '../components/cards/CardStyle.vue';
    import Loading from '../components/Loading.vue';
    import setStartingValues from '../mixins/setStartingValues';

    export default {
        mixins: [setStartingValues],
        components: {
            Loading,
            CardStyle,
            AppIntro,
        },
        data () {
            return {
                type: 'radio',
                index: 'style',
            };
        },
        computed: {
            ...mapState({
                nodes: (state) => state.styles.all,
            }),
        },
        async created () {
            this.$store.commit('tabs/setCurrentTab', this.$route.name);
            await this.$store.dispatch('styles/getAll');
            this.setStartingValues();
        },
    };
</script>

<style lang="scss" scoped>
    @import "../assets/scss/essentials";

    .c-styles {
        display: grid;
        grid-gap: rem(15);

        @include media-breakpoint-up(md) {
            grid-template-columns: repeat(2, 1fr);
        }

        @include media-breakpoint-up(lg) {
            grid-template-columns: repeat(3, 1fr);
        }
    }
</style>
