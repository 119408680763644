// eslint-disable-next-line
import { afasService } from '../../services';

// state
const state = {
    status: {},
    errors: [],
};

// actions
const actions = {
    save ({ dispatch, commit, rootState }, data) {
        commit('request');

        const { account } = rootState;

        return afasService
            .save(data, account)
            .then((result) => {
                commit('success');

                console.log(result);

                return result;
            })
            .catch((error) => {
                commit('failure');
                dispatch('alert/error', error, { root: true });

                return error;
            });
    },

};

// mutations
const mutations = {
    // save
    request (state) {
        state.status = { saving: true };
    },
    success (state) {
        state.status = {};
    },
    failure (state) {
        state.status = {};
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
};
