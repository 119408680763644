import axios from 'axios';
import { API_URL } from './api';

export const contactService = {
    /*save (formData) {
        console.log('contactService: save');

        return axios
            .post(`${API_URL}/contact`, formData)
            .then((response) => response);
    },

    update (formData) {
        console.log('contactService: update');

        return axios
            .put(`${API_URL}/contact/${formData.id}`, formData)
            .then((response) => response);
    },*/

    request () {
        console.log('contactService: request');

        return axios
            .post(`${API_URL}/contact`)
            .then((response) => response);
    },

    /*get (formData) {
        console.log('contactService: get');

        return axios
            .get(`${API_URL}/contact`, formData)
            .then((response) => {
                if (response.data.list)
                {
                    return response.data.list;
                }

                return response;
            });
    },

    delete (id) {
        console.log('contactService: delete');

        return axios
            .delete(`${API_URL}/contact/${id}`)
            .then((response) => response);
    },*/
};

export default contactService;
