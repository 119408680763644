<template>
    <div class="u-cover" :class="[className, { 'is-downloaded': downloaded }]" :style="styles"></div>
</template>

<script>
    export default {
        name: 'ObservedImage',
        data () {
            return {
                downloaded: false,
                styles: [],
            };
        },
        props: {
            src: {
                type: String,
                required: true,
            },
            className: {
                type: String,
                required: true,
            },
        },
        mounted () {
            // this.observe();

            this.downloaded = true;
            this.styles.push({
                backgroundImage: `url(${this.src})`,
            });
        },
        destroyed () {
            if (this.observer)
            {
                this.observer.disconnect();
            }

            if (this.image)
            {
                this.image.src = '';
            }
        },
        methods: {
            observe () {
                if (this.downloaded) return;

                this.observer = new IntersectionObserver((entries) => {
                    entries.forEach((entry) => {
                        const { isIntersecting } = entry;

                        if (isIntersecting)
                        {
                            this.image = new Image();
                            this.image.onload = (bmp) => {
                                const ratio = bmp.target.height / bmp.target.width;

                                this.styles.push({
                                    backgroundImage: `url(${bmp.target.src})`,
                                });

                                if (this.className.includes('padding'))
                                {
                                    this.styles.push({
                                        paddingBottom: `${ratio * 100}%`,
                                        backgroundImage: `url(${bmp.target.src})`,
                                    });
                                }

                                if (this.observer)
                                {
                                    this.observer = this.observer.disconnect();
                                }

                                this.downloaded = true;
                            };

                            this.image.src = this.src;
                        }
                    });
                });

                this.observer.observe(this.$el);
            },
        },
    };
</script>
