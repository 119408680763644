<template>
    <div class="c-card || c-style" :class="{ 'is-checked': isSelected(index, card.id, card.checked) }">
        <observed-image class-name="c-style__img" :src="card.image"/>

        <label class="custom-control-label" :for="card.id">
            <input type="radio" class="custom-control-input" :id="card.id" name="card" :value="card.name" :checked="isSelected(index, card.id, card.checked)" @change="onChange($event, card)">
            <span class="custom-control-name">{{ card.name }}</span>
        </label>

        <router-link :to="'/detail/style/' + card.id" class="c-card__link">
            <svg class="c-card__link-svg" xmlns="http://www.w3.org/2000/svg" width="15" height="15">
                <path class="c-card__link-bg" d="M7.5 0A7.5 7.5 0 1 1 0 7.5 7.5 7.5 0 0 1 7.5 0z"></path>
                <path class="c-card__link-border" d="M7.5 1A6.507 6.507 0 0 0 1 7.5C1 11.084 3.916 14 7.5 14S14 11.084 14 7.5 11.084 1 7.5 1m0-1a7.5 7.5 0 1 1 0 15 7.5 7.5 0 0 1 0-15z"></path>
                <text class="c-card__link-text" transform="translate(6 11)" font-size="10" font-family="Arial-BoldMT, Arial" font-weight="700"><tspan x="0" y="0">i</tspan></text>
            </svg>
            <span class="u-hidden-sm-down">Lees meer</span>
        </router-link>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';
    import ObservedImage from '../ObservedImage.vue';

    export default {
        name: 'CardStyle',
        components: { ObservedImage },
        props: {
            card: {
                type: Object,
                required: true,
            },
            index: {
                type: String,
                required: true,
            },
            type: {
                type: String,
                required: true,
            },
        },
        computed: {
            ...mapGetters('summary', { isSelected: 'isSelected' }),
        },
        methods: {
            onChange (event, payload) {
                // reset summaries
                if (this.$store.state.summary.style.length > 0)
                {
                    this.$store.dispatch('summary/resetSummary', ['role', 'size', 'interior', 'exterior', 'installation']);
                }

                // disable next tabs
                const nextTabs = this.$store.getters['tabs/getNextTabsFromIndex']('role');
                this.$store.dispatch('tabs/disableTabs', nextTabs);

                // add the summary
                this.$store.dispatch('summary/addToSummary', {
                    payload,
                    index: this.index,
                    type: this.type,
                });

                // update tab
                this.$store.dispatch('tabs/updateTab', {
                    index: this.index,
                    type: this.type,
                });
            },
        },
    };
</script>

<style lang="scss" scoped>
    @import "../../assets/scss/essentials";

    .c-style {
        padding: 0;
        border-width: rem(4);

        &:before {
            @include fill;
            background-color: rgba(black, .4);
            content: "";
            display: block;
            transition: $transition-base;
            z-index: 1;
        }

        &__img {
            height: rem(200);
            /*opacity: 0;
            transition: $transition-base;

            &.is-downloaded {
                opacity: 1;
            }*/

            @include media-breakpoint-only(sm) {
                height: rem(300);
            }

            @include media-breakpoint-up(xl) {
                height: rem(260);
            }
        }

        @include hover-focus {
            border-color: $white;

            &:before {
                background-color: rgba(black, 0);
            }
        }

        // IE

        $grid: 3;

        @for $i from 1 through $grid {
            &:nth-child(#{$grid}n + #{$i}) {
                -ms-grid-column: $i;
            }
        }

        &:nth-child(-n + #{$grid}) {
            -ms-grid-row: 1;
        }

        &:nth-child(#{$grid}n + 1):nth-last-child(-n + #{$grid}),
        &:nth-child(#{$grid}n + 1):nth-last-child(-n + #{$grid}) ~ & {
            -ms-grid-row: 2;
        }
    }

    .custom-control-label {
        @include fill;
        margin-bottom: 0;
        padding: rem(15);
        z-index: 2;

        @include media-breakpoint-up(sm) {
            padding: rem(30) rem(40);
        }
    }

    .custom-control-name {
        color: $white;

        &:before {
            @include square(rem(28));
        }

        &:after {
            left: rem(6);
        }
    }
</style>
