import axios from 'axios';

const baseUrl = 'https://53364.resttest.afas.online/ProfitRestServices';

const verkooprelatie = '12702';
const verantwoordelijke = '6552';
const marktsegment = 'L';

const token = '<token><version>1</version><data>8983408B630C4EA0AB94EDF60C907F23415815404BC8F993C698F5851BD7F4B0</data></token>';
const encodedToken = window.btoa(token);
const headers = {
    'Content-Type': 'application/json',
    Authorization: `AfasToken ${encodedToken}`,
};

export const afasService = {
    save (formData, account) {
        console.log('afasService: save');

        const configuration = JSON.parse(formData.configuration);
        const { user } = account;

        // roles
        const opslag = configuration.role.find((c) => c.id === 1);
        const werkplaats = configuration.role.find((c) => c.id === 2);
        const showroom = configuration.role.find((c) => c.id === 3);
        const kantoor = configuration.role.find((c) => c.id === 4);

        // size
        const length = configuration.size.find((c) => c.id === 'length');
        const width = configuration.size.find((c) => c.id === 'width');

        // exterior
        const loopdeur = configuration.exterior.find((l) => l.id === 1);
        const overheaddeur = configuration.exterior.find((l) => l.id === 2);
        const losdock = configuration.exterior.find((l) => l.id === 3);
        const lichtstraat = configuration.exterior.find((l) => l.id === 4);
        const lift = configuration.exterior.find((l) => l.id === 5);
        const schuifdeur = configuration.exterior.find((l) => l.id === 6);
        const screens = configuration.exterior.find((l) => l.id === 7);
        const zonwering = configuration.exterior.find((l) => l.id === 8);

        // interior
        const comfort = configuration.interior.id === 1;
        const elegant = configuration.interior.id === 2;
        const industrieel = configuration.interior.id === 3;

        // installation
        const duurzaam = configuration.installation.id === 1;
        const beng = configuration.installation.id === 2;
        const nom = configuration.installation.id === 3;

        const data = {
            CmForecast: {
                Element: {
                    Fields: {
                        PrId: verkooprelatie, // verkooprelatie
                        Ds: formData.name, // omschrijving
                        EmId: verantwoordelijke, // verantwoordelijke
                        DaEn: '2022-05-28', // verwachte einddatum
                        ViGr: marktsegment, // marktsegment
                        AmF: formData.price, // offertebedrag
                        U7D3921294B730D6987738EAC5778CD53: '', // opmerking
                        UF328B1CD46EF2C0BC06AD7831FD1119C: configuration.style.name, // stijl
                        U2798BE5740D888A6FA41D5BE6F6A9593: opslag ? opslag.quantity : 'Nee', // opslag
                        U2148CD8440DDFBA8E0081DA2B8A8FD5A: showroom ? showroom.quantity : 'Nee', // showroom
                        UC68AF2CA4CAA1B7C2C508B20ED5A8C82: kantoor ? kantoor.quantity : 'Nee', // kantoor
                        UCED685144AE29F42929101A2E6AF9A33: length.quantity, // totale lengte
                        UCD7743D84040634B1F14229DBAA6989B: width.quantity, // totale breedte
                        UBF79F8C944C3CAF2E0B5DF8E788B485B: configuration.surfaceArea, // totale oppervlakte
                        UCA2392E24D63D3DBE7A2A97030A47E18: configuration.style.height, // pand hoogte
                        UFC463D5C41E7FDFCF723E9BDAED42B7A: loopdeur.quantity, // loopdeur
                        U1AFE4C364DDE0EE2EF3E6AB8ED246C80: overheaddeur.quantity, // overheaddeur
                        U6F90985C478109D562B787BE8953602D: losdock.quantity, // laad- en losdock
                        UD03BADBB431C036593EF7790DA55BCA0: lichtstraat.quantity, // lichtstraat
                        UE9010EF447D53FBF587FFDAC22E6724F: lift.quantity, // personenlift
                        UDD2AB89043D4F80FD00AC1A499533A06: schuifdeur.quantity, // schuifdeur entree
                        U20A8396544BEE1FFA5CE7F9F25DDA392: screens.quantity, // screens
                        U80BD6A394A5E05C9EA55158D2813DFF4: zonwering.quantity, // zonwerende beglazing
                        U59C3526F4C4B43B7F5C960BAF13B654E: elegant ? 'Ja' : 'Nee', // elegant
                        U6BE016B140724233D007638B854F33CA: nom ? 'Ja' : 'Nee', // nom
                        U7B4C3C7D403D125DE6C4D9858BA441BB: 'Open', // status
                        UA76324F547D6F470BBE7C696B21A34C4: user.name, // naam
                        U47D46390491D156F861BCAA32B2A1293: user.company, // bedrijfsnaam
                        U7A256B40474213AEC0C4A7BEAD448DE1: user.address, // adres
                        U37AD520F4736AF7FCE5D228974521C2F: user.postcode, // postcode
                        U9E2F1F214CD94B33E68BDA9820898ECC: user.city, // plaats
                        U8DDF38C940D1799B85F39DB67FD00160: user.county, // land
                        U91B12F7E4DDAC0796E77F3977D76E3C0: user.email, // email
                        UD5CD4B3F48384CFDB50A6FB52ECC8C31: user.phone, // telefoon
                        UF380E2154C3E8B984F4D36851B98C5FA: user.country, // land
                    },
                },
            },
        };

        const url = `${baseUrl}/connectors/CmForecast`;

        return axios
            .post(url, data, { headers })
            /*.then((response) => {
                console.log(response.data);
            })*/
            .catch((error) => console.error(error));
    },

};

export default afasService;
