<template>
    <form :class="'form' + (theme !== '' ? ' form--' + theme : '')" v-on:submit.prevent="submit" novalidate>
        <div class="form-fieldset">
            <div class="form-group" :class="{ 'has-error': submitted && $v.form.email.$error }">
                <label class="form-label" for="price-email">Email</label>
                <input type="email" v-model="form.email" id="price-email" name="email" class="form-control" placeholder="Vul hier uw email in" />
                <div v-if="submitted && $v.form.email.$error" class="form-validation">
                    <span v-if="!$v.form.email.required">Email is verplicht</span>
                    <span v-if="!$v.form.email.email">Email is ongeldig</span>
                </div>
            </div>
        </div>

        <div class="form-fieldset">
            <div class="custom-checkbox" :class="{ 'has-error': submitted && $v.form.privacy.$error }">
                <label for="price-terms" class="custom-control-label">
                    <input type="checkbox" v-model="form.privacy" name="privacy" id="price-terms" class="custom-control-input" />
                    <span class="custom-control-name">Ja, ik ga akkoord met de Severfield <a href="https://www.vsc.nl/privacy-verklaring" target="_blank">Privacy Verklaring</a></span>
                </label>
                <div v-if="submitted && $v.form.privacy.$error" class="form-validation">
                    <span v-if="!$v.form.privacy.checked">U dient akkoord te gaan</span>
                </div>
            </div>
        </div>

        <div class="form-fieldset">
            <button type="submit" id="ga-price-indication-submit" class="[ c-btn c-btn--primary ] form-submit-btn" :disabled="status.requesting">
                <svg v-show="!status.requesting" xmlns="http://www.w3.org/2000/svg" width="21.422" height="21.422" viewBox="0 0 21.422 21.422">
                    <g fill="none" stroke="#fff" stroke-linecap="round" stroke-miterlimit="10" transform="translate(.5 .5)">
                        <path d="M4.322 10.211h10.995"></path>
                        <circle cx="10.211" cy="10.211" r="10.211"></circle>
                        <path d="M10.21 5.106l5.106 5.106-5.106 5.106"></path>
                    </g>
                </svg>

                <span v-show="status.requesting"><spinner/></span>
                <span v-show="!status.requesting">Aanmelden</span>
            </button>
        </div>
    </form>
</template>

<script>
    import { mapState, mapActions } from 'vuex';
    import { required, email } from 'vuelidate/lib/validators';
    import normalizeFormData from '../../helpers/normalizeFormData';
    import Spinner from '../Spinner.vue';

    export default {
        name: 'PriceForm',
        components: { Spinner },
        data () {
            return {
                form: {
                    email: '',
                    privacy: '',
                },
                submitted: false,
            };
        },
        props: {
            theme: {
                type: String,
                default: '',
            },
        },
        validations: {
            form: {
                email: { required, email },
                privacy: {
                    checked: (value) => value === true,
                },
            },
        },
        computed: {
            ...mapState('prices', ['status']),
        },
        methods: {
            ...mapActions('prices', ['request']),
            async submit (e) {
                this.submitted = true;

                // validate the form
                this.$v.form.$touch();

                // if its still pending or an error is returned do not submit
                if (this.$v.form.$pending || this.$v.form.$error) return;

                // normalize form data
                const data = normalizeFormData(e.target);

                // store email in API
                await this.request(data);

                // emit event to parent
                this.$emit('priceIndicationFormSent');
            },
        },
    };
</script>

<style lang="scss" scoped>
    @import "../../assets/scss/essentials";

    .form {
        grid-gap: rem(30) 0;
    }

    .form-submit-btn {
        margin-top: rem(50);
    }

    .custom-control-name {
        font-size: rem(16);
        font-weight: $font-weight-normal;

        &:after {
            left: rem(4);
        }
    }
</style>
