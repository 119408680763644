const { localStorage } = window;

export function set (key, value) {
    // eslint-disable-next-line
    value = JSON.stringify(value);

    try
    {
        localStorage.setItem(key, value);
    } catch (e)
    {
        console.log(e);
    }
}

export function get (key) {
    try
    {
        return JSON.parse(localStorage.getItem(key));
    } catch (e)
    {
        console.log(e);
        return false;
    }
}

export function remove (key) {
    try
    {
        localStorage.removeItem(key);
    } catch (e)
    {
        console.log(e);
    }
}

export function update (key, value, unique, limit) {
    let json = null;

    try
    {
        json = localStorage.getItem(key);
    } catch (e)
    {
        console.log(e);
    }

    if (!json)
    {
        // console.log([value], value, "setting new");
        set(key, [value]);
    }
    else
    {
        let parsed = JSON.parse(json);

        if (unique)
        {
            const index = parsed.findIndex((obj) => obj.membershipId === value.membershipId);

            if (index > -1)
            {
                parsed.splice(index, 1);
            }

            if (!limit)
            {
                // eslint-disable-next-line
                limit = parsed.length + 1;
            }

            parsed = [value, ...parsed.slice(0, limit)];
        }
        else
        {
            if (!limit)
            {
                // eslint-disable-next-line
                limit = parsed.length + 1;
            }

            parsed = [value, ...parsed.slice(0, limit)];
        }

        // console.log(json, parsed, "updating");
        set(key, parsed);
    }
}
