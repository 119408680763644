// eslint-disable-next-line
import router from '../../helpers/router';
import { configurationService } from '../../services';

// state
const state = {
    status: {},
    current: {},
    errors: [],
};

// actions
const actions = {
    save ({ dispatch, commit }, data) {
        commit('saveRequest');

        return configurationService
            .save(data)
            .then((result) => {
                commit('saveSuccess');
                commit('setCurrent', { id: result.data.id, name: data.name });

                router.push('/account/configurations');

                return result;
            })
            .catch((error) => {
                commit('saveFailure');
                dispatch('alert/error', error, { root: true });

                return error;
            });
    },

    update ({ dispatch, commit }, data) {
        commit('updateRequest');

        return configurationService
            .update(data)
            .then((result) => {
                commit('updateSuccess');
                dispatch('alert/success', result.data.message, { root: true });

                router.push('/account/configurations');

                return result;
            })
            .catch((error) => {
                commit('updateFailure');
                dispatch('alert/error', error, { root: true });

                return error;
            });
    },

    request ({ dispatch, commit }, id) {
        commit('requestRequest');

        return configurationService
            .request(id)
            .then((result) => {
                commit('requestSuccess');
                dispatch('alert/success', result.data.message, { root: true });

                setTimeout(() => {
                    dispatch('alert/clear', null, { root: true });
                }, 1500);

                return result;
            })
            .catch((error) => {
                commit('requestFailure');
                dispatch('alert/error', error, { root: true });

                return error;
            });
    },

    get ({ dispatch, commit }) {
        commit('getRequest');
        dispatch('alert/clear', null, { root: true });

        return configurationService
            .get()
            .then((result) => {
                commit('getSuccess');

                return result;
            })
            .catch((error) => {
                commit('getFailure');
                dispatch('alert/error', error, { root: true });

                return error;
            });
    },

    delete ({ dispatch, commit, state }, id) {
        commit('deleteRequest');

        return configurationService
            .delete(id)
            .then((result) => {
                commit('deleteSuccess');
                dispatch('alert/success', result.data.message, { root: true });

                if (state.current.id && state.current.id === parseFloat(id))
                {
                    commit('resetCurrent');
                }

                setTimeout(() => {
                    dispatch('alert/clear', null, { root: true });
                }, 1500);

                return result;
            })
            .catch((error) => {
                commit('deleteFailure');
                dispatch('alert/error', error, { root: true });

                return error;
            });
    },

    setCurrent ({ dispatch, commit }, { id, name }) {
        commit('setCurrent', { id, name });

        // show alert
        dispatch('alert/success', `${name} is ingeladen`, { root: true });

        // hide alert after 1 second
        setTimeout(() => {
            dispatch('alert/clear', null, { root: true });
        }, 1500);
    },

    resetCurrent ({ dispatch, commit }, { name }) {
        commit('resetCurrent');

        // show alert
        dispatch('alert/success', `${name} is gereset`, { root: true });

        // hide alert after 1 second
        setTimeout(() => {
            dispatch('alert/clear', null, { root: true });
        }, 1500);
    },
};

// mutations
const mutations = {
    // save
    saveRequest (state) {
        state.status = { saving: true };
    },
    saveSuccess (state) {
        state.status = {};
    },
    saveFailure (state) {
        state.status = {};
    },

    // update
    updateRequest (state) {
        state.status = { saving: true };
    },
    updateSuccess (state) {
        state.status = {};
    },
    updateFailure (state) {
        state.status = {};
    },

    requestRequest (state) {
        state.status = { saving: true };
    },
    requestSuccess (state) {
        state.status = {};
    },
    requestFailure (state) {
        state.status = {};
    },

    // get
    getRequest (state) {
        state.status = { loading: true };
    },
    getSuccess (state) {
        state.status = {};
    },
    getFailure (state) {
        state.status = {};
    },

    // delete
    deleteRequest (state) {
        state.status = { deleting: true };
    },
    deleteSuccess (state) {
        state.status = {};
    },
    deleteFailure (state) {
        state.status = {};
    },

    // set current config
    setCurrent (state, { id, name }) {
        state.current = { id, name };
    },

    // reset current config
    resetCurrent (state) {
        state.current = {};
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
};
