<template>
    <div id="app">
        <app-header/>

        <main :class="{ 'o-main': !$route.meta.hideFooter, 'o-main o-main--no-footer': $route.meta.hideFooter, 'o-main o-main--is-account': $route.meta.isAccount, 'o-main o-main--is-fullscreen': $route.meta.isFullscreen }">
            <transition name="fade" mode="out-in" appear>
                <router-view/>
            </transition>
            <alert/>
        </main>

        <app-footer v-if="!$route.meta.hideFooter"/>
    </div>
</template>

<script>
    import Alert from './Alert.vue';
    import AppHeader from './app/AppHeader.vue';
    import AppFooter from './app/AppFooter.vue';

    export default {
        components: { Alert, AppFooter, AppHeader },
        watch: {
            $route () {
                // clear alert on location change
                this.$store.commit('alert/clear');

                // clear errors
                this.$store.commit('summary/resetErrors');
            },
        },
    };
</script>

<style lang="scss">
    @import "../../src/assets/scss/essentials";

    html,
    body {
        // overflow: hidden;
    }

    #app {
        // @include fill;
    }

    .sticky-header {
        @include set-properties(padding-top);
        @include set-properties(padding-bottom, $footer-height);
    }

    .o-main {
        // @include set-properties(margin-bottom, $footer-height);
        background-color: $gray-lightest;
        // height: calc(100vh - #{rem(map_get($header-height, xs))} - #{rem(map_get($footer-height, xs))});
        // overflow-y: auto;
        padding-top: rem(30);
        padding-bottom: rem(30);
        position: relative;

        @include media-breakpoint-up(sm) {
            // height: calc(100vh - #{rem(map_get($header-height, sm))} - #{rem(map_get($footer-height, sm))});
            padding-bottom: rem(50);
            padding-top: rem(50);
        }

        &--no-footer {
            // height: calc(100vh - #{rem(map_get($header-height, xs))});
            // margin-bottom: 0;

            /*@include media-breakpoint-up(sm) {
                height: calc(100vh - #{rem(map_get($header-height, sm))});
            }*/
        }

        &--is-fullscreen {
            height: calc(100vh - #{rem(map_get($header-height, xs))} - #{rem(map_get($footer-height, xs))});

            @include media-breakpoint-up(sm) {
                height: calc(100vh - #{rem(map_get($header-height, sm))} - #{rem(map_get($footer-height, sm))});
            }
        }

        &--is-account {
            padding: 0;
        }
    }

    .o-account {
        display: grid;
        grid-template-areas: "account-body";
        grid-template-columns: 1fr;
        // grid-template-rows: calc(100vh - #{rem(map_get($header-height, xs))});

        @include media-breakpoint-up(lg) {
            grid-template-areas: "account-nav account-body";
            grid-template-columns: rem(250) 1fr;
            grid-template-rows: calc(100vh - #{rem(map_get($header-height, sm))} - #{rem(map_get($footer-height, sm))});
        }

        @include media-breakpoint-up(xl) {
            grid-template-columns: rem($sidebar-width) 1fr;
        }

        &__nav {
            @include zero;
            list-style: none;
            display: flex;
            justify-content: center;

            &-item {
                & + & {
                    margin-left: rem(15);
                }
            }
        }

        &__body {
            grid-area: account-body;
            padding: rem(30);

            @include media-breakpoint-up(lg) {
                padding: rem(50) 5%;
            }

            @include media-breakpoint-up(xl) {
                padding-left: 10%;
                padding-right: 10%;
            }

            @include media-breakpoint-up(xxl) {
                padding-left: 15%;
                padding-right: 15%;
            }
        }
    }

    .o-container {
        max-width: 85%;
        margin: 0 auto;

        &--small {
            @include media-breakpoint-up(sm) {
                max-width: 50%;
            }
        }
    }

    .c-card {
        background-color: $white;
        border: rem(1) solid $gray-light;
        padding: rem(15);
        position: relative;
        transition: border-color .3s $transition-timing-base, background-color .3s $transition-timing-base;
        z-index: 1;

        &__link {
            @include absolute(top rem(20) right rem(20));
            align-items: center;
            display: flex;
            font-weight: $font-weight-black;
            line-height: 1;
            transition: $transition-base;
            text-transform: uppercase;
            z-index: 5;

            // modifiers

            .c-role & {
                top: 50%;
                right: rem(30);
                transform: translateY(-50%);

                @include media-breakpoint-up(sm) {
                    right: rem(50);
                }
            }

            .c-interior & {
                top: rem(15);
                right: rem(15);
            }

            .c-installation & {
                right: rem(30);
                top: rem(35);

                @include media-breakpoint-up(md) {
                    top: rem(45);
                }
            }

            &--disabled {
                pointer-events: none;
            }

            // elements

            &-svg {
                text-transform: none;

                @include media-breakpoint-up(md) {
                    margin-right: rem(5);
                }

                @include media-breakpoint-down(xsl) {
                    transform: scale(1.2);
                }
            }

            &-bg,
            &-border,
            &-text {
                transition: fill .2s ease-in-out;
            }

            &-bg {
                fill: $brand-primary;
            }

            &-border {
                fill: $brand-primary;

                /*.c-interior &,
                .c-style & {
                    fill: $brand-primary;
                }

                .c-installation &,
                .c-role & {
                    fill: $brand-primary;
                }*/
            }

            &-text {
                fill: $white;

                /*.c-interior &,
                .c-style & {
                    fill: $white;
                }

                .c-installation &,
                .c-role & {
                    fill: $white;
                }*/
            }

            // hovers

            .c-interior &,
            .c-style & {
                color: $white;

                @include hover {
                    .c-card__link-border,
                    .c-card__link-bg {
                        fill: $brand-primary;
                    }
                }
            }

            .c-installation &,
            .c-role & {
                color: $text-color;

                @include hover {
                    color: $brand-primary;

                    .c-card__link-border,
                    .c-card__link-bg {
                        fill: $brand-primary;
                    }

                    .c-card__link-text {
                        fill: $white;
                    }
                }
            }
        }

        &.is-checked {
            border-color: $brand-success !important;

            &:before {
                background-color: rgba(black, 0) !important;
            }
        }

        &.is-disabled {
            border-color: $gray-lighter !important;

            &:before {
                background-color: rgba(black, 0) !important;
            }
        }
    }

    .c-grid-list {
        display: grid;
        grid-gap: rem(15);
        grid-template-columns: 1fr;

        > div {
            // IE

            @for $i from 1 through 10 {
                &:nth-child(#{$i}) {
                    -ms-grid-row: $i;
                }
            }
        }
    }

    /**
     * TABLE
     */

    .table {
        background-color: $white;
        width: 100%;
        max-width: 100%;
        margin-bottom: 0;

        th,
        td {
            padding: rem(10) rem(20);
            vertical-align: top;
            border-bottom: $table-border-width solid $gray-light;
        }

        thead th {
            border: 0;
            background: $white;
            color: $text-color;
            font-weight: $font-weight-black;
            font-size: rem(20);

            @include media-breakpoint-up(sm) {
                padding-top: rem(10);
                padding-bottom: rem(10);
            }

            @include media-breakpoint-up(xl) {
                padding-top: rem(15);
                padding-bottom: rem(15);
            }
        }

        tr.is-selected {
            td {
                &:first-child {
                    border: rem(2) solid $brand-success;
                    border-right: none;
                }

                &:last-child {
                    border: rem(2) solid $brand-success;
                    border-left: none;
                }

                &:not(:first-child):not(:last-child) {
                    border: rem(2) solid $brand-success;
                    border-left: $table-border-width solid $gray-light;
                    border-right: $table-border-width solid $gray-light;
                }
            }
        }

        tbody + tbody {
            border-top: (2 * $table-border-width) solid $gray-light;
        }

        .table {
            background-color: $body-bg;
        }

        &--bordered {
            border: $table-border-width solid $gray-light;

            th,
            td {
                border: $table-border-width solid $gray-light;
            }

            thead {
                th,
                td {
                    border-bottom-width: (2 * $table-border-width);
                }
            }
        }

        &--striped {
            tbody tr:nth-of-type(even) {
                background-color: $white;
            }
        }
    }

    /**
     * DETAIL
     */

    .c-detail {
        background-color: $white;
        margin-top: rem(-30);
        margin-bottom: rem(-30);

        @include media-breakpoint-up(sm) {
            margin-top: rem(-50);
            margin-bottom: rem(-50);
        }
    }

    .c-detail-text {
        *:last-child {
            margin-bottom: 0;
        }
    }

    /**
     * TRANSITION
     */

    .fade-enter-active,
    .fade-leave-active {
        transition: opacity .15s;
    }

    .fade-enter,
    .fade-leave-to {
        opacity: 0;
    }

    // btn

    .btn-complete-item {
        transition: all .3s;
    }

    .btn-complete-enter,
    .btn-complete-leave-to {
        opacity: 0;
        transform: scale(0.5);
    }

    .btn-complete-leave-active {
        position: absolute;
    }

    .btn-move {
        transition: transform .3s;
    }

    /**
    *
    */

    .flickity-prev-next-button {
        background-color: $brand-primary !important;
        bottom: 10% !important;
        top: auto !important;
        transform: none !important;
        z-index: 100;

        .flickity-button-icon {
            left: 50% !important;
            top: 50% !important;
            height: 40% !important;
            transform: translate(-50%, -50%);
            width: 40% !important;
        }

        .arrow {
            fill: $white;
        }
    }

    .flickity-prev-next-button.next {
        right: 7.5% !important;
    }

    .flickity-prev-next-button.previous {
        left: auto !important;
        right: calc(7.5% + #{rem(10)} + #{rem(44)}) !important;
    }

    /**
    * TABS
    */

    .vue-tabs {

    }

    .vue-tablist {
        @include zero;
        list-style: none;
        display: flex;
        width: 100%;
    }

    .vue-tab {
        background-color: $white;
        border: rem(1) solid $gray-light;
        cursor: pointer;
        padding: rem(15);
        flex: 1 1 auto;
        transition: $transition-base;
        text-align: center;
        margin-left: rem(-1);

        @include hover-focus {
            background-color: lighten($gray-lighter, 5%);
        }

        &[aria-selected="true"] {
            background-color: $brand-primary;
            color: $white;
        }

        @include media-breakpoint-up(sm) {
            padding: rem(15) rem(30);
        }

        @include media-breakpoint-up(xl) {
            padding: rem(20) rem(30);
            font-size: rem(20);
        }
    }

    .vue-tabpanel {
        padding: rem(30) 0;

        @include media-breakpoint-up(sm) {
            padding: rem(40);
        }
    }

    /**
     * FORMS
     */

    .form {
        display: grid;
        grid-gap: rem(40) 0;
        margin: 0 auto;
        max-width: 100%;

        @include media-breakpoint-up(sm) {
            max-width: 85%;
        }

        @include media-breakpoint-up(xl) {
            grid-gap: rem(75) 0;
            max-width: 50%;
        }

        // IE
        -ms-grid-columns: 1fr;

        &-fieldset {
            display: grid;
            grid-gap: rem(20);

            // IE
            -ms-grid-columns: 1fr;

            @for $i from 1 through 5 {
                &:nth-child(#{$i}) {
                    -ms-grid-column: 1;
                    -ms-grid-row: $i;
                }
            }

            &--horizontal {
                @include media-breakpoint-up(sm) {
                    align-items: center;
                    grid-template-columns: 1fr auto;

                    > *:nth-child(1) { -ms-grid-column: 1; }
                    > *:nth-child(2) { -ms-grid-column: 2; }
                }
            }
        }

        &-group {
            // IE
            -ms-grid-columns: 1fr;

            @for $i from 1 through 10 {
                &:nth-child(#{$i}) {
                    -ms-grid-column: 1;
                    -ms-grid-row: $i;
                }
            }
        }
    }

    .form-submit-btn {
        // justify-self: center;
    }

    .custom-control-name {
        align-self: flex-end;
        color: $text-color;
        display: block;
        font-size: rem(24);
        font-weight: $font-weight-black;
        // filter: drop-shadow(0 0 rem(3) rgba(black, .15));
        padding-left: rem(40);
        position: relative;

        @include media-breakpoint-up(xl) {
            font-size: rem(30);
        }

        // bg
        &::before {
            @include square(rem(24));
            @include absolute(top 50% left 0);
            display: block;
            pointer-events: none;
            content: '';
            user-select: none;
            background-color: transparent;
            border: rem(2) solid $gray-light;
            border-radius: 50%;
            transition: all .3s $transition-timing-base;
            transform: translateY(-50%);
        }

        // icon
        &::after {
            @include square(rem(16));
            @include absolute(top 50% left rem(4));
            background: transparent url('/assets/images/check.svg') center center no-repeat;
            display: block;
            content: '';
            transition: all .3s $transition-timing-base;
            transform: scale(0) translateY(-50%);
        }
    }

    .custom-control-label {
        cursor: pointer;
        display: flex;
        line-height: 1.2;
        margin-bottom: 0;
        text-align: left;

        .is-disabled & {
            cursor: not-allowed;
            opacity: 0.4;
        }
    }

    .custom-control-input {
        position: absolute;
        z-index: -1; // Put the input behind the label so it doesn't overlay text
        opacity: 0;

        &:checked ~ .custom-control-name:before {
            border-color: $brand-success;
            background-color: $brand-success !important;
        }

        &:checked ~ .custom-control-name:after {
            transform: scale(1) translateY(-50%);
        }

        &:active ~ .custom-control-name:before {
            color: $brand-success;
            background-color: $brand-success;
        }

        &:disabled {
            ~ .custom-control-name {
                color: $gray-light;

                &::before {
                    background-color: $gray-light;
                    border-color: $gray-light;
                }
            }
        }
    }

    /**
     * LINK
     */

    .o-c2a-link {
        @include fluid-type(18px, 30px);
        align-items: center;
        display: flex;
        flex: 1 1 auto;
        font-weight: $font-weight-black;
        justify-content: center;
        line-height: 1;
        padding: rem(25);
        transition: background-color $transition-duration $transition-timing-base;
        text-transform: uppercase;

        @include media-breakpoint-up(sm) {
            justify-content: center;
            padding: rem(35);
        }

        @include media-breakpoint-up(xl) {
            padding: rem(41);
        }

        @include hover-focus {
            .o-c2a-link__icon {
                &:before {
                    @include media-breakpoint-up(lg) {
                        width: rem(45);
                    }
                }
            }

            .o-c2a-link__icon-circle {
                @include media-breakpoint-up(lg) {
                    margin-left: rem(40);
                }

                &:before,
                &:after {
                    @include media-breakpoint-up(lg) {
                        left: rem(47);
                    }
                }
            }
        }

        &--primary {
            background-color: $brand-primary;
            color: $white;

            @include hover-focus {
                color: $white;
                background-color: darken($brand-primary, 5%);
            }
        }

        &--tint {
            background-color: $gray-lighter;
            color: $text-color;

            @include hover-focus {
                color: $text-color;
                background-color: darken($gray-lighter, 5%);
            }
        }

        &__text {
            display: inline-block;
            margin-right: rem(15);

            .o-c2a-link--reverse & {
                margin-right: 0;
                margin-left: rem(15);
                order: 2;
            }
        }

        &__icon {
            align-items: center;
            display: inline-flex;
            overflow: hidden;
            position: relative;
            transition: opacity $transition-duration $transition-timing-base, visibility $transition-duration $transition-timing-base;
            pointer-events: none;

            .o-c2a-link--reverse & {
                order: 1;
                transform: rotate(180deg);
            }

            &:before {
                @include absolute(top 50% left rem(6));
                @include size(rem(6), rem(1));
                content: '';
                display: block;
                transition: all $transition-duration $transition-timing-base;

                .o-c2a-link--primary & {
                    background-color: $white;
                }

                .o-c2a-link--tint & {
                    background-color: $brand-primary;
                }
            }

            &-circle {
                @include square(rem(20));
                border-radius: 50%;
                display: inline-block;
                margin-left: 0;
                transition: all $transition-duration $transition-timing-base;

                .o-c2a-link--primary & {
                    border: rem(1) solid $white;
                }

                .o-c2a-link--tint & {
                    border: rem(1) solid $brand-primary;
                }

                &:before,
                &:after {
                    @include absolute(top 50% left rem(8));
                    @include size(rem(5), rem(1));
                    border-radius: rem(10);
                    transform: rotate(40deg);
                    transform-origin: right center;
                    transition: all $transition-duration $transition-timing-base;
                    content: '';
                    display: block;

                    .o-c2a-link--primary & {
                        background: $white;
                    }

                    .o-c2a-link--tint & {
                        background: $brand-primary;
                    }
                }

                &:after {
                    transform: rotate(-40deg);
                }
            }
        }
    }

    /**
     * MODAL
     */
    .sweet-modal {
        background-color: $gray-lighter;
        box-shadow: none;
        border: rem(1) solid $gray-light;
        border-radius: 0;

        @include media-breakpoint-up(xl) {
            max-width: none;
            width: 50%;
        }

        .form {
            @include media-breakpoint-up(xl) {
                max-width: 75%;
            }
        }

        .sweet-content {
            padding: rem(30);
        }

        .sweet-box-actions {
            top: 0;
            right: 0;

            .sweet-action-close {
                @include flex-center;
                background-color: $brand-primary;
                border-radius: 0;
                transition: $transition-base;

                @include media-breakpoint-up(sm) {
                    @include square(rem(50));
                }

                svg {
                    path {
                        transition: $transition-base;
                        fill: $white;
                    }
                }

                @include hover {
                    background-color: darken($brand-primary, 5%);

                    svg {
                        path {
                            fill: $white;
                        }
                    }
                }
            }
        }
    }

    /**
     * DIALOG
     */

    .dg-main-content {
        padding: rem(30) !important;
        max-width: rem(500) !important;
    }

    .dg-content {
        font-size: rem(20) !important;
    }

    .dg-content-body {
        padding-bottom: rem(30) !important;
        border-bottom: rem(1) solid $gray-light !important;
    }

    .dg-content-footer {
        padding-top: rem(30) !important;
    }

    .dg-btn {
        border-radius: 0 !important;
        border-width: rem(1) !important;

        &--cancel {
            background-color: $brand-primary !important;
        }

        &--ok {
            border-color: $brand-primary !important;
            color: $brand-primary !important;
        }
    }
</style>
