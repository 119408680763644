import Vue from 'vue';
import Router from 'vue-router';
import store from '../store/index';

Vue.use(Router);

const { routes } = store.state.routes;

const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
});

// redirect to login page if not logged in and trying to access a restricted page
router.beforeEach((to, from, next) => {
    const authRequired = to.path.includes('account');
    const { loggedIn } = store.state.account.status;
    // const loggedIn = ls.get('account:token');

    if (authRequired && !loggedIn)
    {
        return next('/login');
    }

    return next();
});

// change document title
router.beforeEach((to, from, next) => {
    const nearestWithTitle = to.matched.slice().reverse().find((r) => r.meta && r.meta.title);
    const nearestWithMeta = to.matched.slice().reverse().find((r) => r.meta && r.meta.metaTags);

    if (nearestWithTitle) document.title = nearestWithTitle.meta.title;

    Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map((el) => el.parentNode.removeChild(el));

    if (!nearestWithMeta) return next();

    nearestWithMeta.meta.metaTags.map((tagDef) => {
        const tag = document.createElement('meta');

        Object.keys(tagDef).forEach((key) => {
            tag.setAttribute(key, tagDef[key]);
        });

        // We use this to track which meta tags we create, so we don't interfere with other ones.
        tag.setAttribute('data-vue-router-controlled', '');

        return tag;
    }).forEach((tag) => document.head.appendChild(tag));

    return next();
});

export default router;
