<template>
    <section class="c-intro">
        <slot>This is intro</slot>
    </section>
</template>

<script>
    export default {
        name: 'AppIntro',
    };
</script>

<style lang="scss" scoped>
    @import "../../assets/scss/_essentials.scss";

    .c-intro {
        padding-bottom: rem(30);
        text-align: center;
        position: relative;
        z-index: 2;

        *:last-child {
            margin-bottom: 0;
        }

        @include media-breakpoint-up(sm) {
            padding-bottom: rem(40);
        }
    }
</style>
