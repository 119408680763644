import axios from 'axios';
import { API_URL } from './api';

export const detailsService = {
    getById (index, id) {
        console.log('detailsService', index, id);

        return axios
            .get(`${API_URL}/${index}/${id}`)
            .then((response) => response.data.item);
    },
};

export default detailsService;
