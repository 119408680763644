import * as ls from '../../helpers/localStorage';
import { currency } from '../../helpers/currency';
import { pricesService } from '../../services/prices.service';

const priceIsAvailable = ls.get('price:requested') === true || ls.get('account:token') !== null;

// initial state
const state = {
    priceIsAvailable,
    total: 'N.A.',
    status: {},
};

// getters
const getters = {
    total: (state, getters, rootState) => {
        const items = rootState.summary;
        let total = 0;

        for (const index in items)
        {
            if (Object.prototype.hasOwnProperty.call(items, index))
            {
                for (const node in items[index])
                {
                    if (Object.prototype.hasOwnProperty.call(items[index], node))
                    {
                        const item = items[index][node];
                        total += item.price * item.quantity;
                    }
                }
            }
        }

        return currency(total);
    },
    totalRaw: (state, getters, rootState) => {
        const items = rootState.summary;
        let total = 0;

        for (const index in items)
        {
            if (Object.prototype.hasOwnProperty.call(items, index))
            {
                for (const node in items[index])
                {
                    if (Object.prototype.hasOwnProperty.call(items[index], node))
                    {
                        const item = items[index][node];
                        total += item.price * item.quantity;
                    }
                }
            }
        }

        return total;
    },
};

// actions
const actions = {
    request ({ dispatch, commit }, data) {
        commit('requestStart');

        pricesService
            .request(data)
            .then(() => {
                commit('requestSuccess');
            })
            .catch((error) => {
                commit('requestError');
                dispatch('alert/error', error, { root: true });
            });
    },
};

// mutations
const mutations = {
    // request
    requestStart (state) {
        state.status = { requesting: true };
    },
    requestSuccess (state) {
        state.status = {};
        state.priceIsAvailable = true;
    },
    requestError (state) {
        state.status = {};
        state.priceIsAvailable = false;
    },
    // set price
    priceIsAvailable (state, available) {
        state.priceIsAvailable = available;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
