<template>
    <nav class="c-nav">
        <ul class="c-nav__list">
            <li class="c-nav__item" v-for="tab in tabs" :key="tab.path">
                <router-link :class="'c-nav__link' + (tab.disabled ? ' c-nav__link--disabled' : '')" active-class="c-nav__link--current" exact-active-class="c-nav__link--current" :to="!tab.disabled ? tab.path : '#0'" exact>
                    {{ tab.name }}
                </router-link>
            </li>
            <li class="c-nav__item">
                <router-link :class="'c-nav__link' + (!tabsCompleted ? ' c-nav__link--disabled' : '')" active-class="c-nav__link--current" exact-active-class="c-nav__link--current" :to="tabsCompleted ? '/summary' : '#0'" exact>
                    Samenvatting
                </router-link>
            </li>
        </ul>
    </nav>
</template>

<script>
    import { mapGetters } from 'vuex';

    export default {
        computed: {
            ...mapGetters('tabs', { tabs: 'getTabs', tabsCompleted: 'tabsCompleted' }),
            ...mapGetters('summary', { summary: 'getSummaryByIndex' }),
        },
    };
</script>

<style lang="scss" scoped>
    @import "../../assets/scss/_essentials.scss";

    .c-nav {
        display: flex;
        justify-content: space-between;

        @include media-breakpoint-down(sm) {
            display: none;
        }

        &__list {
            @include zero;
            display: flex;
            list-style: none;
        }

        &__item {
            display: inline-block;

            & + & {
                margin-left: rem(20);
            }
        }

        &__link {
            color: $text-color;
            text-transform: uppercase;
            transition: $transition-base;

            @include media-breakpoint-only(md) {
                font-size: rem(14);
            }

            @include hover-focus {
                color: $brand-primary;
            }

            &--current {
                color: $brand-primary;
                font-weight: $font-weight-bold;
            }

            &--disabled {
                color: $gray-light;
                pointer-events: none;
            }
        }
    }
</style>
