import { mapActions } from 'vuex';

export default {
    methods: {
        ...mapActions('summary', ['addToSummary']),
        ...mapActions('tabs', ['updateTab']),
        setStartingValues () {
            // only add the starting values when the store is empty
            if (this.$store.state.summary[this.index].length === 0)
            {
                // get default
                const nodes = this.type === 'number' ? this.nodes : this.nodes.filter((node) => node.is_default);

                // add start values to summary
                for (const node of nodes)
                {
                    const quantity = this.index === 'exterior' ? 0 : 1;

                    this.addToSummary({
                        payload: {
                            ...node,
                            quantity,
                        },
                        index: this.index,
                        type: this.type,
                    });
                }

                // update tab
                this.updateTab({
                    index: this.index,
                    type: this.type,
                });
            }
        },
    },
};
