<template>
    <a :href="to" :class="btnClass">
        <slot>This is a btn</slot>
    </a>
</template>

<script>
    export default {
        name: 'AppButton',
        props: {
            btnClass: {
                type: String,
                default: 'c-btn c-btn--primary',
            },
            to: {
                type: String,
                default: '#0',
            },
        },
    };
</script>

<style lang="scss">
    @import "../../assets/scss/_essentials.scss";

    .c-btn {
        align-items: center;
        color: $text-color;
        cursor: pointer;
        border: none;
        display: inline-flex;
        justify-content: center;
        text-transform: uppercase;
        transition: $transition-base;
        padding: rem(15) rem(30);
        outline: 0;

        @include media-breakpoint-down(xsl) {
            font-size: rem(14);
        }

        .disabled,
        &[disabled] {
            opacity: 0.5;
            pointer-events: none;
        }

        @include hover {
            background-color: lighten($gray-lightest, 2%);
        }

        &:focus {
            outline: 0;
        }

        svg {
            pointer-events: none;
            margin-right: rem(15);
        }

        & + & {
            margin-left: rem(15);
        }

        &__text {
            @include media-breakpoint-down(xsl) {
                display: none;
            }
        }

        &--primary {
            background-color: $brand-primary;
            color: $white;

            @include hover {
                background-color: darken($brand-primary, 5%);
                color: $white;
            }
        }

        &--outline-primary {
            border: rem(1) solid $brand-primary;
            color: $brand-primary;

            @include hover {
                background-color: $brand-primary;
                color: $white;
            }
        }

        &--tint {
            background-color: $gray-light;

            @include hover {
                background-color: lighten($gray-light, 5%);
                color: $text-color;
            }
        }

        &--success {
            background-color: $brand-success;
            color: $white;

            @include hover {
                background-color: darken($brand-success, 5%);
                color: $white;
            }
        }

        &--outline-tint {
            border: rem(1) solid $gray-light;
            color: $gray-light;
        }

        &--square {
            @include square(rem(55));
            padding: 0;

            @include media-breakpoint-up(sm) {
                @include square(rem(80));
            }

            &.c-btn--small {
                @include square(rem(30));
                @include flex-center;
            }
        }

        &--small:not(.c-btn--square) {
            font-size: rem(14);
            height: rem(30);
            padding: 0 rem(10);
            text-transform: none;

            svg {
                margin-right: rem(5) !important;
            }
        }
    }

    .c-btn-group {
        &,
        & > div {
            align-items: center;
            display: flex;
            height: inherit;
        }

        &--stacked {
            .c-btn {
                margin-left: 0;

                svg {
                    margin-right: 0;
                }
            }
        }
    }
</style>
