import axios from 'axios';
import { API_URL } from './api';

export const configurationService = {
    save (formData) {
        console.log('configurationService: save');

        return axios
            .post(`${API_URL}/configurations`, formData)
            .then((response) => response);
    },

    update (formData) {
        console.log('configurationService: update');

        return axios
            .put(`${API_URL}/configurations/${formData.id}`, formData)
            .then((response) => response);
    },

    request (id) {
        console.log('configurationService: request');

        return axios
            .put(`${API_URL}/configurations/request/${id}`)
            .then((response) => response);
    },

    get (formData) {
        console.log('configurationService: get');

        return axios
            .get(`${API_URL}/configurations`, formData)
            .then((response) => {
                if (response.data.list)
                {
                    return response.data.list;
                }

                return response;
            });
    },

    delete (id) {
        console.log('configurationService: delete');

        return axios
            .delete(`${API_URL}/configurations/${id}`)
            .then((response) => response);
    },
};

export default configurationService;
