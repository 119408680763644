import { stylesService } from '../../services';

// initial state
const state = {
    all: [],
};

// getters
const getters = {};

// actions
const actions = {
    getAll ({ dispatch, commit }) {
        // const nodes = await stylesService.getAll();
        // commit('setNodes', typeof nodes !== 'undefined' ? nodes : []);
        return stylesService
            .getAll()
            .then((nodes) => {
                commit('setNodes', nodes);

                return nodes;
            })
            .catch((error) => {
                commit('setNodes', []);
                dispatch('alert/error', error, { root: true });

                return error;
            });
    },
};

// mutations
const mutations = {
    setNodes (state, themes) {
        state.all = themes;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
