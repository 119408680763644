import Vue from 'vue';
import Vuex from 'vuex';

// modules
import styles from './modules/styles.module';
import roles from './modules/roles.module';
import sizes from './modules/sizes.module';
import interior from './modules/interior.module';
import exterior from './modules/exterior.module';
import installations from './modules/installations.module';

// misc
import alert from './modules/alert.module';
import summary from './modules/summary.module';
import prices from './modules/prices.module';
import tabs from './modules/tabs.module';
import routes from './modules/routes.module';
import account from './modules/account.module';
import configuration from './modules/configuration.module';
import afas from './modules/afas.module';
import contact from './modules/contact.module';

Vue.use(Vuex);

const store = new Vuex.Store({
    modules: {
        alert,
        styles,
        roles,
        sizes,
        interior,
        exterior,
        installations,
        summary,
        prices,
        tabs,
        routes,
        account,
        configuration,
        afas,
        contact,
    },
});

export default store;
