import axios from 'axios';
import * as ls from '../helpers/localStorage';
import { API_URL } from './api';

export const accountService = {
    user () {
        console.log('accountService: user');

        return axios
            .get(`${API_URL}/account`)
            .then((response) => {
                // check if we get an account
                if (response.data.account)
                {
                    // store the firstname
                    ls.set('account:firstname', response.data.account.firstname);

                    return response.data.account;
                }

                return response;
            });
    },

    register (formData) {
        console.log('accountService: register');

        return axios
            .post(`${API_URL}/register`, formData)
            .then((response) => {
                // check if we get a token
                if (response.data.token)
                {
                    // store the token
                    ls.set('account:token', response.data.token);

                    // get the user
                    this.user();
                }

                return response;
            });
    },

    login (formData) {
        console.log('accountService: login');

        return axios
            .post(`${API_URL}/login`, formData)
            .then((response) => {
                // check if we get a token
                if (response.data.token)
                {
                    // store the token
                    ls.set('account:token', response.data.token);

                    return response.data.token;
                }

                return response;
            });
    },

    logout () {
        console.log('accountService: logout');

        return axios
            .get(`${API_URL}/logout`)
            .then((response) => {
                // remove the token
                ls.remove('account:token');
                ls.remove('account:firstname');

                return response;
            });
    },

    passwordEmail (formData) {
        console.log('accountService: passwordEmail');

        return axios
            .post(`${API_URL}/password/email`, formData)
            .then((response) => {
                console.log(response);

                return response;
            });
    },

    passwordReset (formData) {
        console.log('accountService: passwordReset');

        return axios
            .post(`${API_URL}/password/reset`, formData)
            .then((response) => {
                console.log(response);

                return response;
            });
    },

    delete () {
        console.log('accountService: delete');

        return axios
            .delete(`${API_URL}/account/delete`)
            .then((response) => {
                // remove the token
                ls.remove('account:token');
                ls.remove('account:firstname');
                ls.remove('request:email');
                ls.remove('price:requested');

                return response;
            });
    },

    update (formData) {
        console.log('accountService: update');

        return axios
            .put(`${API_URL}/account`, formData)
            .then((response) => response);
    },

    updatePassword (formData) {
        console.log('accountService: updatePassword');

        return axios
            .put(`${API_URL}/account/password`, formData)
            .then((response) => response);
    },
};

export default accountService;
