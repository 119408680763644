import axios from 'axios';
import * as ls from '../helpers/localStorage';
import { API_URL } from './api';

export const pricesService = {
     request (formData) {
        console.log('pricesService: request');

        return axios
            .post(`${API_URL}/price-indication`, formData)
            .then((response) => {
                ls.set('price:requested', true);
                ls.set('request:email', formData.email);

                return response.data;
            });
    },
    /*request (formData) {
        console.log('pricesService: request');

        return axios
            .post(`${API_URL}/prices`, formData)
            .then((response) => {
                ls.set('price:requested', true);
                ls.set('request:email', formData.email);

                return response.data;
            });
    },*/
    get (formData) {
        console.log('pricesService: get');

        return axios
            .post(`${API_URL}/prices`, formData)
            .then((response) => response.data);
    },
    /*get (params) {
        console.log('pricesService: get');
        const qString = Object.keys(params).map((key) => `${key}=${params[key]}`).join('&');

        return axios
            .get(`${API_URL}/prices?${qString}`)
            .then((response) => response.data);
    },*/
};

export default pricesService;
