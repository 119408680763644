// initial state
const state = {
    tabs: [
        {
            path: '/',
            name: 'Stijl',
            index: 'style',
            disabled: false,
        },
        {
            path: '/roles',
            name: 'Functie',
            index: 'role',
            disabled: true,
        },
        {
            path: '/sizes',
            name: 'Afmetingen',
            index: 'size',
            disabled: true,
        },
        {
            path: '/exterior',
            name: 'Exterieur',
            index: 'exterior',
            disabled: true,
        },
        {
            path: '/interior',
            name: 'Interieur',
            index: 'interior',
            disabled: true,
        },
        {
            path: '/installation',
            name: 'Installatie',
            index: 'installation',
            disabled: true,
        },
    ],
    currentTab: 0,
};

// getters
const getters = {
    getTabs: (state) => state.tabs,
    getNextTabsFromIndex: (state) => (index) => {
        const currentTabIndex = state.tabs.findIndex((tab) => tab.index === index);
        return state.tabs.filter((tab, tabIndex) => tabIndex > currentTabIndex);
    },
    getNextTab: (state) => state.tabs[state.currentTab + 1],
    getPrevTab: (state) => state.tabs[state.currentTab - 1],
    totalTabs: (state) => state.tabs.length,
    totalTabsDisabled: (state) => state.tabs.filter((tab) => tab.disabled).length,
    tabsCompleted: (state, getters) => getters.totalTabsDisabled === 0,
    currentTab: (state) => state.currentTab,
};

// actions
const actions = {
    updateTab ({ rootState, commit, getters }, { index }) {
        if (index === 'style')
        {
            commit('enableTab', getters.getNextTab);
        }

        if (index === 'role')
        {
            commit('updateTab', { tab: getters.getNextTab, disabled: rootState.summary.role.length === 0 });
        }

        if (index === 'size')
        {
            commit('updateTab', { tab: getters.getNextTab, disabled: false });
        }

        if (index === 'exterior')
        {
            commit('updateTab', { tab: getters.getNextTab, disabled: rootState.summary.exterior.length === 0 });
        }

        if (index === 'interior')
        {
            commit('updateTab', { tab: getters.getNextTab, disabled: rootState.summary.interior.length === 0 });
        }
    },
    disableTab ({ commit }, tab) {
        commit('disableTab', tab);
    },
    enableTab ({ commit }, tab) {
        commit('enableTab', tab);
    },
    disableTabs ({ commit, state }, tabs = state.tabs) {
        for (const tab of tabs)
        {
            commit('disableTab', tab);
        }
    },
    enableTabs ({ commit, state }, tabs = state.tabs) {
        for (const tab of tabs)
        {
            commit('enableTab', tab);
        }
    },
};

// mutations
const mutations = {
    setCurrentTab: (state, name) => {
        state.currentTab = parseInt(Object.keys(state.tabs).find((key) => state.tabs[key].name === name), 10);
    },
    updateTab: (state, { tab, disabled }) => {
        tab.disabled = disabled;
    },
    enableTab: (state, tab) => {
        tab.disabled = false;
    },
    disableTab: (state, tab) => {
        tab.disabled = true;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
