export default function normalizeFormData (target) {
    // get the from data
    const formData = new FormData(target);

    // normalize the form data
    const data = {};

    for (const item of formData)
    {
        data[item[0]] = item[1];
    }

    return data;
}
