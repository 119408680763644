import { interiorsService } from '../../services';

// initial state
const state = {
    all: [],
};

// getters
const getters = {};

// actions
const actions = {
    getAll ({ dispatch, commit }) {
        // const nodes = await interiorsService.getAll();
        // commit('setNodes', typeof nodes !== 'undefined' ? nodes : []);
        return interiorsService
            .getAll()
            .then((nodes) => {
                commit('setNodes', nodes);
            })
            .catch((error) => {
                commit('setNodes', []);
                dispatch('alert/error', error, { root: true });

                return error;
            });
    },
};

// mutations
const mutations = {
    setNodes (state, nodes) {
        state.all = nodes;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
