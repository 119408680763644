<template>
    <div :class="'spinner' + (theme !== '' ? ' spinner--' + theme : '')"></div>
</template>

<script>
    export default {
        name: 'Spinner',
        props: {
            theme: {
                type: String,
                default: '',
            },
        },
    };
</script>

<style lang="scss" scoped>
    @import "../assets/scss/_essentials.scss";

    $border-width: 2;
    $dimension: 22;

    .spinner {
        @include square(rem($dimension));
        border-radius: 50%;
        border: rem($border-width) solid $white;
        position: relative;

        &:before {
            @include square(rem($dimension));
            @include absolute(top rem(-$border-width) left rem(-$border-width));
            animation: spinner 1s cubic-bezier(0.4, 0, 0.2, 1) infinite;
            border-radius: 50%;
            border: rem($border-width) solid $brand-primary;
            border-bottom-color: transparent;
            border-left-color: transparent;
            border-right-color: transparent;
            content: "";
            display: block;
        }

        &--primary {
            border-color: $brand-primary;

            &:before {
                border-top-color: $white;
            }
        }
    }

    @keyframes spinner {
        100% {
            transform: rotate(360deg);
        }
    }
</style>
