<template>
    <div>
        <app-intro>
            <h2 class="alpha">Prijsindicatie inzien?</h2>
            <h3>Laat dan uw e-mail adres achter</h3>
        </app-intro>

        <price-form v-on:priceIndicationFormSent="closePriceIndicationModal" theme="light"/>
    </div>
</template>

<script>
    import AppIntro from './app/AppIntro.vue';
    import PriceForm from './forms/PriceForm.vue';

    export default {
        name: 'PriceIndication',
        components: { PriceForm, AppIntro },
        methods: {
            closePriceIndicationModal () {
                this.$emit('priceIndicationFormSent');
            },
        },
    };
</script>
