import * as ls from '../../helpers/localStorage';
// eslint-disable-next-line
import router from '../../helpers/router';
import { accountService } from '../../services';

const token = ls.get('account:token');
const state = token
    ? { status: { loggedIn: true }, user: null }
    : { status: { loggedIn: false }, user: null };

// actions
const actions = {
    login ({ dispatch, commit }, data) {
        console.log('accountStore: login');
        commit('loginRequest');

        return accountService
            .login(data)
            .then((result) => {
                commit('loginSuccess');
                commit('prices/priceIsAvailable', true, { root: true });

                // get the user when login is successful
                dispatch('user');

                // if url contains login redirect to account
                if (router.currentRoute.path.indexOf('login') > 0)
                {
                    router.push('/account');
                }

                return result;
            })
            .catch((error) => {
                commit('loginFailure');
                dispatch('alert/error', error, { root: true });

                return error;
            });
    },

    user ({ dispatch, commit }) {
        console.log('accountStore: user');
        commit('userRequest');

        return accountService
            .user()
            .then((account) => {
                commit('userSuccess', account);
                commit('prices/priceIsAvailable', true, { root: true });
            })
            .catch((error) => {
                console.error(error);
                commit('userFailure');
                ls.remove('account:token');
                commit('prices/priceIsAvailable', false, { root: true });
                router.push('/login');
                dispatch('alert/error', error, { root: true });

                return error;
            });
    },

    update ({ dispatch, commit }, data) {
        console.log('accountStore: update');
        commit('updateRequest');

        return accountService
            .update(data)
            .then((response) => {
                commit('updateSuccess');
                dispatch('alert/success', response.data.message, { root: true });
            })
            .catch((error) => {
                commit('updateFailure');
                dispatch('alert/error', error, { root: true });

                return error;
            });
    },

    updatePassword ({ dispatch, commit }, data) {
        console.log('accountStore: updatePassword');
        commit('updateRequest');

        return accountService
            .updatePassword(data)
            .then((response) => {
                commit('updateSuccess');
                dispatch('alert/success', response.data.message, { root: true });
            })
            .catch((error) => {
                commit('updateFailure');
                dispatch('alert/error', error, { root: true });

                return error;
            });
    },

    delete ({ dispatch, commit }) {
        console.log('accountStore: delete');
        commit('deleteRequest');

        return accountService
            .delete()
            .then(() => {
                commit('deleteSuccess');
                commit('prices/priceIsAvailable', false, { root: true });
                commit('configuration/resetCurrent', {}, { root: true });
            }).catch((error) => {
                commit('deleteFailure');
                dispatch('alert/error', error, { root: true });

                return error;
            });
    },

    logout ({ commit }) {
        accountService.logout();
        commit('logout');
        commit('prices/priceIsAvailable', false, { root: true });
        commit('configuration/resetCurrent', {}, { root: true });

        if (router.currentRoute.path.indexOf('account') > 0)
        {
            router.push('/');
        }
    },

    passwordEmail ({ commit, dispatch }, data) {
        commit('passwordEmailRequest');

        return accountService
            .passwordEmail(data)
            .then(() => {
                commit('passwordEmailSuccess');
            })
            .catch((error) => {
                commit('passwordEmailFailure');
                dispatch('alert/error', error, { root: true });

                return error;
            });
    },

    passwordReset ({ commit, dispatch }, data) {
        commit('passwordResetRequest');

        return accountService
            .passwordReset(data)
            .then(() => {
                commit('passwordResetSuccess');
            })
            .catch((error) => {
                commit('passwordResetFailure');
                dispatch('alert/error', error, { root: true });

                return error;
            });
    },
};

// mutations
const mutations = {
    // login
    loginRequest (state) {
        state.status = { loggingIn: true };
    },
    loginSuccess (state) {
        state.status = { loggedIn: true };
    },
    loginFailure (state) {
        state.status = {};
    },

    // register
    registerRequest (state) {
        state.status = { registering: true };
    },
    registerSuccess (state) {
        state.status = { registered: true };
    },
    registerFailure (state) {
        state.status = {};
    },

    // update
    updateRequest (state) {
        state.status = { updating: true };
    },
    updateSuccess (state) {
        state.status = { loggedIn: true };
    },
    updateFailure (state) {
        state.status = { loggedIn: true };
    },

    // user
    userRequest (state) {
        state.status = { loggedIn: true, loading: true };
    },
    userSuccess (state, account) {
        state.status = { loggedIn: true };
        state.user = account;
    },
    userFailure (state) {
        state.status = {};
        state.user = null;
    },

    // password email
    passwordEmailRequest (state) {
        state.status = { sending: true };
    },
    passwordEmailSuccess (state) {
        state.status = { sent: true };
    },
    passwordEmailFailure (state) {
        state.status = {};
    },

    // password reset
    passwordResetRequest (state) {
        state.status = { resetting: true };
    },
    passwordResetSuccess (state) {
        state.status = { resetting: true };
    },
    passwordResetFailure (state) {
        state.status = {};
    },

    // logout
    logout (state) {
        state.status = { loggedOut: true };
        state.user = null;
    },

    // delete
    deleteRequest (state) {
        state.status = { deleting: true };
    },
    deleteSuccess (state) {
        state.status = { deleted: true };
        state.user = null;
    },
    deleteFailure (state) {
        state.status = {};
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
};
