<template>
    <footer class="c-footer">
        <div class="c-btn-group">
            <router-link to="/overview" class="[ c-btn ] c-footer__btn c-footer__btn--summary" v-if="showOverviewButton">
                Bekijk overzicht
            </router-link>

            <div class="c-footer__price-container">
                <span v-if="showPriceIndication" class="c-footer__price">
                    <span class="c-footer__price-label">Prijsindicatie:</span>
                    <span class="c-footer__price-unit">{{ total }}</span>
                </span>
                <span v-if="showPriceIndicationText">
                    Prijs wordt getoond nadat de <strong>{{ nextBtn.name.toLowerCase() }}</strong> is ingevuld
                </span>
                <span v-if="showPriceRequest">
                    Laat uw
                    <a href="#0" v-on:click="openPriceIndicationModal" class="u-text-primary u-font-weight-black">email</a>
                    achter voor een prijsindicatie
                </span>
            </div>
        </div>

        <div class="c-btn-group" v-if="!isSummary">
            <router-link class="[ c-btn c-btn--tint ] c-footer__btn" :to="prevBtn.path" v-if="!prevBtn.disabled">
                <svg xmlns="http://www.w3.org/2000/svg" width="21.422" height="21.422" viewBox="0 0 21.422 21.422">
                    <g fill="none" stroke="#6B7073" stroke-linecap="round" stroke-miterlimit="10" transform="rotate(180 10.461 10.461)">
                        <path d="M4.322 10.211h10.995"></path>
                        <circle cx="10.211" cy="10.211" r="10.211"></circle>
                        <path d="M10.21 5.106l5.106 5.106-5.106 5.106"></path>
                    </g>
                </svg>
                <span class="c-btn__text">Terug</span>
            </router-link>
            <router-link class="[ c-btn c-btn--primary ] c-footer__btn" :to="nextBtn.path" v-if="!nextBtn.disabled">
                <svg xmlns="http://www.w3.org/2000/svg" width="21.422" height="21.422" viewBox="0 0 21.422 21.422">
                    <g fill="none" stroke="#fff" stroke-linecap="round" stroke-miterlimit="10" transform="translate(.5 .5)">
                        <path d="M4.322 10.211h10.995"></path>
                        <circle cx="10.211" cy="10.211" r="10.211"></circle>
                        <path d="M10.21 5.106l5.106 5.106-5.106 5.106"></path>
                    </g>
                </svg>
                <span class="c-btn__text">{{ nextBtn.name }}</span>
            </router-link>
            <router-link class="[ c-btn c-btn--primary ] c-footer__btn" to="/summary" v-if="currentTabIndex === totalTabs">
                    <svg xmlns="http://www.w3.org/2000/svg" width="21.422" height="21.422" viewBox="0 0 21.422 21.422">
                        <g fill="none" stroke="#fff" stroke-linecap="round" stroke-miterlimit="10" transform="translate(.5 .5)">
                            <path d="M4.322 10.211h10.995"></path>
                            <circle cx="10.211" cy="10.211" r="10.211"></circle>
                            <path d="M10.21 5.106l5.106 5.106-5.106 5.106"></path>
                        </g>
                    </svg>
                    <span class="c-btn__text">Samenvatting</span>
                </router-link>
        </div>

        <div class="c-btn-group" v-if="isSummary">
            <!--<router-link class="[ c-btn c-btn&#45;&#45;tint ] c-footer__btn" to="/contact">
                <svg xmlns="http://www.w3.org/2000/svg" width="21.422" height="21.422" viewBox="0 0 21.422 21.422">
                    <g fill="none" stroke="#6B7073" stroke-linecap="round" stroke-miterlimit="10" transform="translate(.5 .5)">
                        <path d="M4.322 10.211h10.995"></path>
                        <circle cx="10.211" cy="10.211" r="10.211"></circle>
                        <path d="M10.21 5.106l5.106 5.106-5.106 5.106"></path>
                    </g>
                </svg>
                <span class="c-btn__text">Contact</span>
            </router-link>-->

            <a href="#0" class="[ c-btn c-btn--primary ] c-footer__btn" v-scroll-to="'.c-summary-save'" v-if="errors.length === 0">
                <svg xmlns="http://www.w3.org/2000/svg" width="21.422" height="21.422" viewBox="0 0 21.422 21.422">
                    <g fill="none" stroke="#fff" stroke-linecap="round" stroke-miterlimit="10" transform="translate(.5 .5)">
                        <path d="M4.322 10.211h10.995"></path>
                        <circle cx="10.211" cy="10.211" r="10.211"></circle>
                        <path d="M10.21 5.106l5.106 5.106-5.106 5.106"></path>
                    </g>
                </svg>
                <span class="c-btn__text">Opslaan</span>
            </a>
        </div>

        <sweet-modal class="modal" ref="priceIndicationModal">
            <price-indication v-on:priceIndicationFormSent="closePriceIndicationModal"></price-indication>
        </sweet-modal>
    </footer>
</template>

<script>
    import { mapState, mapGetters } from 'vuex';
    import { SweetModal } from 'sweet-modal-vue';
    import PriceIndication from '../PriceIndication.vue';

    export default {
        components: {
            PriceIndication,
            SweetModal,
        },
        computed: {
            nextBtn () {
                const tab = this.getNextTab;

                return {
                    path: tab ? tab.path : '',
                    name: tab ? tab.name : '',
                    disabled: tab ? tab.disabled : true,
                };
            },
            prevBtn () {
                const tab = this.getPrevTab;

                return {
                    path: tab ? tab.path : '',
                    name: tab ? tab.name : '',
                    disabled: tab ? tab.disabled : true,
                };
            },
            currentTabIndex () {
                return this.currentTab + 1;
            },
            showPriceIndication () {
                return this.priceIsAvailable && this.summary('role').length && this.summary('size').length;
                // return (this.priceIsAvailable && this.tabsCompleted) || (this.priceIsAvailable && this.currentTabIndex > 2);
            },
            showPriceIndicationText () {
                return this.priceIsAvailable && (this.summary('role').length === 0 || this.summary('size').length === 0);
                // return (this.priceIsAvailable && this.tabsCompleted) || (this.priceIsAvailable && this.currentTabIndex < 3);
            },
            showPriceRequest () {
                return (!this.priceIsAvailable || this.tabsCompleted || this.currentTabIndex > 2) && !this.showPriceIndication;
            },
            showOverviewButton () {
                // return !this.isSummary && this.showPriceIndication;
                return !this.isSummary && (this.currentTabIndex > 2 || this.tabsCompleted);
            },
            isSummary () {
                return this.$route.path === '/summary';
            },
            ...mapState({
                priceIsAvailable: (state) => state.prices.priceIsAvailable,
            }),
            ...mapGetters('tabs', {
                tabs: 'getTabs',
                totalTabs: 'totalTabs',
                currentTab: 'currentTab',
                getNextTab: 'getNextTab',
                getPrevTab: 'getPrevTab',
                tabsCompleted: 'tabsCompleted',
            }),
            ...mapGetters('prices', {
                total: 'total',
            }),
            ...mapGetters('summary', {
                summary: 'getSummaryByIndex',
                errors: 'getErrors',
            }),
        },
        methods: {
            openPriceIndicationModal () {
                this.$refs.priceIndicationModal.open();
            },
            closePriceIndicationModal () {
                this.$refs.priceIndicationModal.close();
            },
        },
    };
</script>

<style lang="scss" scoped>
    @import "../../assets/scss/_essentials.scss";

    .c-footer {
        @include set-properties(height, $footer-height);
        @include fixed(bottom 0 left 0 right 0);
        align-items: center;
        background-color: $white;
        border-top: rem(1) solid $gray-light;
        display: flex;
        justify-content: space-between;
        padding-left: rem(15);
        z-index: 100;

        @include media-breakpoint-up(sm) {
            padding: rem(20);
            padding-left: 0;
        }

        &__price-container {
            line-height: 1.2;

            @include media-breakpoint-down(sm) {
                margin-right: rem(15);
            }

            @include media-breakpoint-up(sm) {
                margin-left: rem(30);
            }

            @include media-breakpoint-up(lg) {
                font-size: rem(20);
            }
        }

        &__price {
            font-weight: $font-weight-black;
            text-transform: uppercase;
            line-height: 1.2;

            &-label {
                color: $brand-primary;
                display: block;
            }

            &-unit {
                @include fluid-type(24px, 30px);
            }
        }

        &__btn {
            height: inherit;

            svg {
                @include media-breakpoint-down(xsl) {
                    margin-right: 0;
                }
            }

            & + & {
                @include media-breakpoint-down(sm) {
                    margin-left: 0;
                }
            }

            &:not(&--summary) {
                @include media-breakpoint-up(sm) {
                    height: auto;
                }
            }

            &--summary {
                border-right: rem(1) solid $gray-light;

                @include media-breakpoint-down(sm) {
                    display: none;
                }
            }
        }
    }
</style>
