const state = {
    type: null,
    message: null,
};

const actions = {
    success ({ commit }, message) {
        commit('success', message);
    },
    error ({ commit }, message) {
        commit('error', message);
    },
    clear ({ commit }, message) {
        commit('success', message);
    },
};

const mutations = {
    success (state, message) {
        state.type = 'success';
        state.message = message;
    },
    error (state, error) {
        state.type = 'error';

        if (error.response)
        {
            if (error.response.status === 501 || error.response.status === 405)
            {
                state.message = 'Er ging iets mis met de API';
                return;
            }

            if (error.response.status === 404)
            {
                state.message = error.response.data.message;
                return;
            }

            if (typeof error.response.data.error === 'string')
            {
                state.message = error.response.data.error;
            }
            else if (typeof error.response.data.error === 'undefined')
            {
                if (error.response.data.message)
                {
                    state.message = error.response.data.message;
                }
                else
                {
                    state.message = `${error.response.statusText}: Er ging iets mis met de API`;
                }
            }
            else
            {
                state.message = error.response.data.error.join(' ');
            }
        }
        else if (error.request)
        {
            state.message = `${error.response.statusText}: Kon niet verbinden met de API`;
        }
        else
        {
            state.message = error.message;
        }
    },
    warning (state, message) {
        state.type = 'warning';
        state.message = message;
    },
    clear (state) {
        state.type = null;
        state.message = null;
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
};
