// eslint-disable-next-line
// import router from '../../helpers/router';
import { contactService } from '../../services';

// state
const state = {
    status: {},
    errors: [],
};

// actions
const actions = {

    request ({ dispatch, commit }, id) {
        commit('requestRequest');

        return contactService
            .request(id)
            .then((result) => {
                commit('requestSuccess');
                dispatch('alert/success', result.data.message, { root: true });

                setTimeout(() => {
                    dispatch('alert/clear', null, { root: true });
                }, 2000);

                return result;
            })
            .catch((error) => {
                commit('requestFailure');
                dispatch('alert/error', error, { root: true });

                setTimeout(() => {
                    dispatch('alert/clear', null, { root: true });
                }, 2000);

                return error;
            });
    },

    /*get ({ dispatch, commit }) {
        commit('getRequest');
        dispatch('alert/clear', null, { root: true });

        return contactService
            .get()
            .then((result) => {
                commit('getSuccess');

                return result;
            })
            .catch((error) => {
                commit('getFailure');
                dispatch('alert/error', error, { root: true });

                return error;
            });
    },

    delete ({ dispatch, commit, state }, id) {
        commit('deleteRequest');

        return contactService
            .delete(id)
            .then((result) => {
                commit('deleteSuccess');
                dispatch('alert/success', result.data.message, { root: true });

                if (state.current.id && state.current.id === parseFloat(id))
                {
                    commit('resetCurrent');
                }

                setTimeout(() => {
                    dispatch('alert/clear', null, { root: true });
                }, 1500);

                return result;
            })
            .catch((error) => {
                commit('deleteFailure');
                dispatch('alert/error', error, { root: true });

                return error;
            });
    },*/

};

// mutations
const mutations = {

    requestRequest (state) {
        state.status = { saving: true };
    },
    requestSuccess (state) {
        state.status = {};
    },
    requestFailure (state) {
        state.status = {};
    },

    // get
    getRequest (state) {
        state.status = { loading: true };
    },
    getSuccess (state) {
        state.status = {};
    },
    getFailure (state) {
        state.status = {};
    },

    // delete
    deleteRequest (state) {
        state.status = { deleting: true };
    },
    deleteSuccess (state) {
        state.status = {};
    },
    deleteFailure (state) {
        state.status = {};
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
};
