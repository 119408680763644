// initial state
import Styles from '../../views/Styles.vue';

const delimiter = '-';
const siteTitle = `Voortman Steel Construction ${delimiter} Bedrijfspand configurator`;
const state = {
    routes: [
        // tabs
        {
            path: '/',
            name: 'Stijl',
            index: 'style',
            component: Styles,
            props: {
                isTab: true,
            },
            meta: {
                title: `Stijl ${delimiter} ${siteTitle}`,
                metaTags: [{
                    name: 'description',
                    content: 'Omschrijving',
                }],
            },
        },
        {
            path: '/roles',
            name: 'Functie',
            index: 'role',
            component: () => import(/* webpackChunkName: "role" */ '../../views/Roles.vue'),
            props: {
                isTab: true,
            },
            meta: {
                title: `Functie ${delimiter} ${siteTitle}`,
                metaTags: [{
                    name: 'description',
                    content: 'Omschrijving',
                }],
            },
        },
        {
            path: '/sizes',
            name: 'Afmetingen',
            index: 'size',
            component: () => import(/* webpackChunkName: "sizes" */ '../../views/Sizes.vue'),
            props: {
                isTab: true,
            },
            meta: {
                title: `Afmetingen ${delimiter} ${siteTitle}`,
                metaTags: [{
                    name: 'description',
                    content: 'Omschrijving',
                }],
            },
        },
        {
            path: '/exterior',
            name: 'Exterieur',
            index: 'exterior',
            component: () => import(/* webpackChunkName: "exterior" */ '../../views/Exterior.vue'),
            props: {
                isTab: true,
            },
            meta: {
                title: `Exterieur ${delimiter} ${siteTitle}`,
                metaTags: [{
                    name: 'description',
                    content: 'Omschrijving',
                }],
            },
        },
        {
            path: '/interior',
            name: 'Interieur',
            index: 'interior',
            component: () => import(/* webpackChunkName: "interior" */ '../../views/Interior.vue'),
            props: {
                isTab: true,
            },
            meta: {
                isFullscreen: true,
                title: `Interieur ${delimiter} ${siteTitle}`,
                metaTags: [{
                    name: 'description',
                    content: 'Omschrijving',
                }],
            },
        },
        {
            path: '/installation',
            name: 'Installatie',
            index: 'installation',
            component: () => import(/* webpackChunkName: "installation" */ '../../views/Installations.vue'),
            props: {
                isTab: true,
            },
            meta: {
                title: `Installatie ${delimiter} ${siteTitle}`,
                metaTags: [{
                    name: 'description',
                    content: 'Omschrijving',
                }],
            },
        },
        // app
        {
            path: '/summary',
            name: 'Samenvatting',
            component: () => import(/* webpackChunkName: "summary" */ '../../views/Summary.vue'),
            meta: {
                title: `Samenvatting ${delimiter} ${siteTitle}`,
                metaTags: [{
                    name: 'description',
                    content: 'Omschrijving',
                }],
            },
        },
        {
            path: '/overview',
            name: 'Overzicht',
            component: () => import(/* webpackChunkName: "overview" */ '../../views/Overview.vue'),
            meta: {
                title: `Overzicht ${delimiter} ${siteTitle}`,
                metaTags: [{
                    name: 'description',
                    content: 'Omschrijving',
                }],
            },
        },
        // account
        {
            path: '/login',
            name: 'Inloggen',
            component: () => import(/* webpackChunkName: "login" */ '../../views/account/Login.vue'),
            meta: {
                hideFooter: true,
                title: `Inloggen ${delimiter} ${siteTitle}`,
                metaTags: [{
                    name: 'description',
                    content: 'Omschrijving',
                }],
            },
        },
        {
            path: '/register',
            name: 'Registreren',
            component: () => import(/* webpackChunkName: "register" */ '../../views/account/Register.vue'),
            meta: {
                hideFooter: true,
                title: `Registreren ${delimiter} ${siteTitle}`,
                metaTags: [{
                    name: 'description',
                    content: 'Omschrijving',
                }],
            },
        },
        {
            path: '/account',
            name: 'Account',
            component: () => import(/* webpackChunkName: "account" */ '../../views/account/Account.vue'),
            meta: {
                hideFooter: true,
                isAccount: true,
                title: `Account ${delimiter} ${siteTitle}`,
                metaTags: [{
                    name: 'description',
                    content: 'Omschrijving',
                }],
            },
        },
        {
            path: '/account/configurations',
            name: 'Configuraties',
            component: () => import(/* webpackChunkName: "configurations" */ '../../views/account/Configurations.vue'),
            meta: {
                hideFooter: true,
                isAccount: true,
                title: `Configuraties ${delimiter} ${siteTitle}`,
                metaTags: [{
                    name: 'description',
                    content: 'Omschrijving',
                }],
            },
        },
        {
            path: '/forgot-password',
            name: 'Wachtwoord vergeten',
            component: () => import(/* webpackChunkName: "login" */ '../../views/account/ForgotPasswordEmail.vue'),
            meta: {
                hideFooter: true,
                title: `Wachtwoord vergeten ${delimiter} ${siteTitle}`,
                metaTags: [{
                    name: 'description',
                    content: 'Omschrijving',
                }],
            },
        },
        {
            path: '/reset-password',
            name: 'Wachtwoord herstellen',
            component: () => import(/* webpackChunkName: "login" */ '../../views/account/ForgotPasswordReset.vue'),
            meta: {
                hideFooter: true,
                title: `Wachtwoord herstellen ${delimiter} ${siteTitle}`,
                metaTags: [{
                    name: 'description',
                    content: 'Omschrijving',
                }],
            },
        },
        // detail pages
        {
            path: '/detail/style/:id',
            name: 'Stijl detail',
            component: () => import(/* webpackChunkName: "style-detail" */ '../../views/detail/Style.vue'),
            meta: {
                title: `Stijl ${delimiter} ${siteTitle}`,
                metaTags: [{
                    name: 'description',
                    content: 'Omschrijving',
                }],
            },
        },
        {
            path: '/detail/role/:id',
            name: 'Functie detail',
            component: () => import(/* webpackChunkName: "role-detail" */ '../../views/detail/Role.vue'),
            meta: {
                title: `Functie ${delimiter} ${siteTitle}`,
                metaTags: [{
                    name: 'description',
                    content: 'Omschrijving',
                }],
            },
        },
        {
            path: '/detail/area',
            name: 'Minimale kavel',
            component: () => import(/* webpackChunkName: "area-detail" */ '../../views/detail/Area.vue'),
            meta: {
                title: `Minimale kavel ${delimiter} ${siteTitle}`,
                metaTags: [{
                    name: 'description',
                    content: 'Omschrijving',
                }],
            },
        },
        {
            path: '/detail/interior/:id',
            name: 'Interieur detail',
            component: () => import(/* webpackChunkName: "interior-detail" */ '../../views/detail/Interior.vue'),
            meta: {
                title: `Interieur ${delimiter} ${siteTitle}`,
                metaTags: [{
                    name: 'description',
                    content: 'Omschrijving',
                }],
            },
        },
        {
            path: '/detail/installation/:id',
            name: 'Installatie detail',
            component: () => import(/* webpackChunkName: "installation-detail" */ '../../views/detail/Installation.vue'),
            meta: {
                title: `Installatie ${delimiter} ${siteTitle}`,
                metaTags: [{
                    name: 'description',
                    content: 'Omschrijving',
                }],
            },
        },
        // otherwise redirect to home
        {
            path: '*',
            name: '404 not found',
            component: () => import(/* webpackChunkName: "404-not-found" */ '../../views/404.vue'),
            meta: {
                title: `404 ${delimiter} ${siteTitle}`,
                metaTags: [{
                    name: 'description',
                    content: 'Omschrijving',
                }],
            },
        },
    ],
};

// getters
const getters = {};

// actions
const actions = {};

// mutations
const mutations = {};

export default {
    state,
    getters,
    actions,
    mutations,
};
