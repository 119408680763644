<template>
    <div class="c-loading" v-if="!nodes.length">
        <spinner/>
        <slot>Loading component...</slot>
    </div>
</template>

<script>
    import Spinner from './Spinner.vue';

    export default {
        name: 'Loading',
        components: { Spinner },
        props: {
            nodes: {
                required: true,
                type: Array,
            },
        },
    };
</script>

<style lang="scss" scoped>
    @import "../assets/scss/_essentials.scss";

    .c-loading {
        align-items: center;
        background-color: $gray-lighter;
        display: flex;
        padding: rem(10) rem(20);
        justify-content: center;

        .spinner {
            display: inline-block;
            margin-right: rem(10);
        }
    }
</style>
