import axios from 'axios';
import { API_URL } from './api';

export const rolesService = {
    getAll () {
        console.log('rolesService: getAll');

        return axios
            .get(`${API_URL}/roles`)
            .then((response) => {
                if (response.data.list)
                {
                    return response.data.list;
                }

                return response;
            });
    },
    getSelected (style) {
        console.log(`rolesService: getSelected => ${style}`);

        return axios
            .get(`${API_URL}/roles/style/${style}`)
            .then((response) => {
                if (response.data.list)
                {
                    return response.data.list;
                }

                return response;
            });
    },
};

export default rolesService;
