import * as ls from '../../helpers/localStorage';

// initial state
const state = {
    style: [],
    role: [],
    size: [],
    exterior: [],
    interior: [],
    installation: [],
    surfaceArea: 0,
    errors: [],
};

// getters
const getters = {
    getSummaryByIndex: (state) => (index) => {
        if (!state[index]) return [];
        return state[index];
    },
    isSelected: (state) => (index, id, isSelected = false) => {
        if (isSelected) return true;
        if (!state[index]) return false;

        const found = state[index].find((node) => node.id === id);
        return typeof found !== 'undefined';
    },
    getQuantity: (state) => (index, id, quantity) => {
        if (!state[index]) return quantity;

        const found = state[index].find((node) => node.id === id);
        return typeof found !== 'undefined' ? found.quantity : quantity;
    },
    getSurfaceArea: (state) => state.surfaceArea,
    getErrors: (state) => state.errors,
    getNormalizedItems: (state) => ({
            style: state.style.find((item) => item.id),
            role: state.role,
            size: state.size,
            exterior: state.exterior,
            interior: state.interior.find((item) => item.id) || [],
            installation: state.installation.find((item) => item.id) || [],
            surfaceArea: state.surfaceArea,
        }),
    getNormalizedItemsJSON: (state) => JSON.stringify({
            style: state.style.find((item) => item.id),
            role: state.role,
            size: state.size,
            exterior: state.exterior,
            interior: state.interior.find((item) => item.id) || [],
            installation: state.installation.find((item) => item.id) || [],
            surfaceArea: state.surfaceArea,
        }),
};

// actions
const actions = {
    addToSummary ({ state, commit, getters }, { payload, index, type }) {
        if (!payload) return;

        const found = state[index] ? state[index].find((node) => node.id === payload.id) : false;

        // always empty the summary when type is radio
        if (type === 'radio')
        {
            commit('emptySummaryByIndex', index);
        }

        // check if item already exists
        if (found)
        {
            if (type === 'radio' && (index === 'interior' || index === 'installation'))
            {
                commit('updateQuantity', { payload, index });
            }
            else if (type === 'select' || type === 'number')
            {
                commit('updateQuantity', { payload, index });
            }
            else
            {
                commit('removeFromSummary', { payload, index });
            }
        }
        // else push it to the summary
        else
        {
            commit('pushItemToSummary', { payload, index, type });
        }

        // store it locally
        ls.set('configuration', getters.getNormalizedItems);
    },
    updateSummary ({ state, commit }, { payload, index }) {
        const found = state[index] ? state[index].find((node) => node.id === payload.id) : false;

        if (found)
        {
            commit('updateSummary', { payload, index });
        }
    },
    resetSummary ({ commit }, indices) {
        for (const index of indices)
        {
            commit('emptySummaryByIndex', index);
        }
    },
    removeFromSummary ({ commit }, { payload, index }) {
        commit('removeFromSummary', { payload, index });
    },
    updateSurfaceArea ({ commit }, area) {
        commit('updateSurfaceArea', area);
    },
    resetErrors ({ commit }) {
        commit('resetErrors');
    },
};

// mutations
const mutations = {
    pushItemToSummary (state, { payload, index, type }) {
        const price = payload.price_m2 ? payload.price_m2 : payload.price ? payload.price : 0;

        // push it in the corresponding state
        state[index].push({
            ...payload,
            type,
            index,
            price,
        });
    },
    updateQuantity (state, { payload, index }) {
        const item = state[index].find((item) => item.id === payload.id);
        item.quantity = payload.quantity;
    },
    updateSummary (state, { payload, index }) {
        const node = state[index].find((node) => node.id === payload.id);

        node.price = payload.price_m2 ? payload.price_m2 : payload.price ? payload.price : 0;
        node.quantity = payload.quantity;
    },
    removeFromSummary (state, { payload, index }) {
        state[index] = state[index].filter((node) => node.id !== payload.id);
    },
    emptySummaryByIndex (state, index) {
        state[index] = [];
    },
    updateSurfaceArea (state, area) {
        state.surfaceArea = area;
    },
    pushError (state, message) {
        state.errors.push(message);
    },
    resetErrors (state) {
        state.errors = [];
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
